import {
  defineNuxtRouteMiddleware,
  navigateTo,
} from 'nuxt/app';
import { useAuthStore } from '@stores/useAuthStore';

export default defineNuxtRouteMiddleware(async () => {
  const { user } = toRefs(useAuthStore());

  if (!user.value?.is_mogelijk_partner) {
    return navigateTo({ path: '/' });
  }
});
